import { Location } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { DiagnosticsActions } from '../diagnostics/diagnostics.actions';
import { MessagesActions } from '../messages/messages.actions';
import { InputActions } from './input.actions';

@Injectable()
export class InputEffects {
  private _actions$: Actions = inject(Actions);

  LoadDiagnostics$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(InputActions.SetIdentifier),
      filter(() => this._location.path().includes('/analytics')),
      switchMap(() => of(DiagnosticsActions.LoadDiagnostics())),
    ),
  );

  LoadMessages$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(InputActions.SetIdentifier),
      filter(() => this._location.path().includes('/debugger')),
      switchMap(() => of(MessagesActions.LoadMessages())),
    ),
  );

  constructor(public _location: Location) {}
}
