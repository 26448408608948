import {
  UpsertAccountRequest,
  UpsertAccountRequestAccountStatus,
  UpsertAccountRequestAccountType,
} from '../generated';

export const testUpsertAccountRequest: UpsertAccountRequest = {
  account_status: UpsertAccountRequestAccountStatus.Prospect,
  account_type: UpsertAccountRequestAccountType.EndCustomer,
  name: 'Test Name',
  name2: 'Test Name 2',
  country_code: 'Test Country Code',
  street_name: 'Test Street Name',
  house_number: 'Test House Number',
  street: 'Test Street',
  city: 'Test City',
  postal_code: 'Test Postal Code',
  email: 'test@email.com',
  url: 'Test Url',
  phone_number: 'Test Phone Number',
  fax: 'Test Fax',
  market_ids: ['test market id 1', 'test market id 2'],
  account_id: 'Test Account Id',
};
