import { Sort } from '@angular/material/sort';
import { TableSettings } from '@design-system/components/table-settings';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { DateTime } from '@paldesk/shared-lib/utils/date-utils';
import { MessageFilterPayload } from '../../shared';
import { PageQuery } from '../../shared/models/page-query';
import { DeviceMessage } from '@paldesk/shared-lib/data-access/provisioning-service-generated';
import { MessagesActions } from './messages.actions';

const today = new DateTime();
const dateFormat = 'YYYY-MM-DD';

export interface MessagesState extends EntityState<DeviceMessage> {
  isMessagesRequestLoading: boolean;
  messages: DeviceMessage[];
  userSettings: TableSettings[];
  isUserSettingsLoading: boolean;
  query: PageQuery;
  rowCount: number;
  sort: Sort;
  error: string | null;
  filterQuery: MessageFilterPayload;
}

export const adapter: EntityAdapter<DeviceMessage> =
  createEntityAdapter<DeviceMessage>({
    selectId: (message) => message.timestamp as number,
  });

export const initialState: MessagesState = adapter.getInitialState({
  isMessagesRequestLoading: true,
  messages: [] as DeviceMessage[],
  userSettings: [] as TableSettings[],
  isUserSettingsLoading: true,
  query: { pageSize: 10, pageIndex: 0 },
  sort: { active: 'CreatedAt', direction: 'desc' },
  rowCount: 0,
  error: null,
  filterQuery: {
    fromDate: today.format(dateFormat),
    toDate: today.format(dateFormat),
  } as MessageFilterPayload,
});

const _messagesReducer = createReducer(
  initialState,
  on(MessagesActions.LoadMessages, (state) => ({
    ...state,
    error: null,
    messages: initialState.messages,
    isMessagesRequestLoading: true,
  })),
  on(MessagesActions.LoadMessagesSuccess, (state, { payload }) => ({
    ...state,
    rowCount: payload?.row_count ? payload.row_count : 0,
    messages: payload?.messages ? payload.messages : initialState.messages,
    isMessagesRequestLoading: false,
  })),
  on(MessagesActions.LoadMessagesError, (state, { payload }) => ({
    ...state,
    error: payload,
    isMessagesRequestLoading: false,
  })),
  on(MessagesActions.SaveQueryMessages, (state, { payload }) => ({
    ...state,
    query: payload,
  })),
  on(MessagesActions.SaveSortMessages, (state, { payload }) => ({
    ...state,
    sort: payload,
  })),
  on(MessagesActions.SaveFilterQueryMessages, (state, { payload }) => ({
    ...state,
    filterQuery: payload,
  })),

  on(MessagesActions.LoadUserTableSettingsMessages, (state) => ({
    ...state,
    isUserSettingsLoading: true,
    userSettings: initialState.userSettings,
  })),
  on(
    MessagesActions.LoadUserTableSettingsMessagesSuccess,
    (state, { payload }) => ({
      ...state,
      isUserSettingsLoading: false,
      userSettings: payload,
    }),
  ),
);

export function messagesReducer(
  state: MessagesState | undefined,
  action: Action,
) {
  return _messagesReducer(state, action);
}
