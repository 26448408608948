import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ActivatedRouteSnapshot, RouterModule, Routes } from '@angular/router';
import {
  DS_APP_WRAPPER_CONFIG,
  DsAppWrapperConfig,
} from '@design-system/feature/app-wrapper-v2';
import { AuthGuard, AuthTokens, SharedFeatAuthModule } from '@features/auth';
import {
  GoogleAnalytics4MeasurementId,
  GoogleAnalytics4Module,
} from '@shared-lib/google-analytics';
import {
  API_MANAGEMENT_CONFIG_TOKEN,
  HttpErrorInterceptor,
  HttpInterceptorModule,
  HttpInterceptorTokens,
} from '@utils/interceptors';
import { TelematicDoctorEffects, TelematicDoctorReducers } from './store';

import { NgModule, inject } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BASE_PATH as BPM_BASE_PATH } from '@data-access/bpm-generated';
import { TableSettingsIntl } from '@design-system/components/table-settings';
import { AppWrapperModule } from '@design-system/feature/app-wrapper-v2';
import { SharedLibFeatTranslationModule } from '@features/translation';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateService } from '@ngx-translate/core';
import { BASE_PATH as USER_PROFILE_BASE_PATH } from '@paldesk/shared-lib/data-access/identity-service-generated';
import { BASE_PATH } from '@paldesk/shared-lib/data-access/provisioning-service-generated';
import { SharedLibFeaturesFreshdeskWidgetModule } from '@paldesk/shared-lib/features/freshdesk-widget';
import { APPLICATION_INSIGHTS_KEY } from '@shared-lib/app-insights';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { SharedTelematicDoctorModule } from './shared';
import { TableSettingsI18n } from './shared/i18n/table-settings-i18n';

export function getTableSettingsIntl(translateService: TranslateService) {
  return new TableSettingsI18n(translateService).getIntl();
}

export const appRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        redirectTo: 'analytics',
        pathMatch: 'full',
      },
      {
        path: 'analytics',
        loadChildren: () =>
          import('./analytics/analytics.module').then((m) => m.AnalyticsModule),
        canActivate: [
          (next: ActivatedRouteSnapshot) => inject(AuthGuard).canActivate(next),
        ],
        data: {
          title: 'analytics.name',
        },
      },
      {
        path: 'debugger',
        loadChildren: () =>
          import('./debugger/debugger.module').then((m) => m.DebuggerModule),
        canActivate: [
          (next: ActivatedRouteSnapshot) => inject(AuthGuard).canActivate(next),
        ],
        data: {
          title: 'debugger.name',
        },
      },
    ],
  },
  {
    path: 'callback',
    children: [],
  },
  {
    path: 'special',
    loadChildren: () =>
      import('@design-system/feature/special-pages').then(
        (m) => m.DsSpecialPagesModule,
      ),
  },
  {
    path: '**',
    redirectTo: 'special',
  },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    SharedLibFeatTranslationModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes),
    HttpClientModule,
    AppWrapperModule.forRoot([
      {
        provide: DS_APP_WRAPPER_CONFIG,
        useFactory: (): DsAppWrapperConfig => ({
          environment: environment.environment,
          apiBasePath: environment.palfinger_api_management,

          isNativeApp: false,
          paldeskBasePath: environment.LINKS.PALDESK_BASEPATH,
          paldeskSupportPath: environment.LINKS.STATUS_PALDESK_PAGE,
          appInsightsRole: 'FE: Telematic Doctor',
          appGatewayBasePath: environment.palfinger_app_gateway,
          appName: 'Telematic Doctor',
          appHelpPath: '/assets/documents/telematic-doctor.pdf',
          statusBannerProductsToCheck: ['PALFINGER Connected Telematic'],
        }),
      },
      {
        provide: APPLICATION_INSIGHTS_KEY,
        useFactory: () => environment.application_insights_key,
      },
    ]),
    HttpInterceptorModule.forRoot({
      provide: API_MANAGEMENT_CONFIG_TOKEN,
      useFactory: () => ({
        subscriptionKey: environment.palfinger_api_management_subs_key,
        whitelistedDomains: environment.domainsForApiManagementSubsKey,
      }),
    }),
    SharedFeatAuthModule.forRoot(),
    StoreModule.forRoot(TelematicDoctorReducers, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot([]),
    TelematicDoctorEffects,
    SharedTelematicDoctorModule,
    StoreDevtoolsModule.instrument({
      maxAge: 50,
      logOnly: environment.production,
      connectInZone: true,
    }),
    SharedLibFeaturesFreshdeskWidgetModule.forRoot({
      widgetId: 15000000344,
    }),
    GoogleAnalytics4Module.forRoot({
      provide: GoogleAnalytics4MeasurementId,
      useFactory: () => environment.google_analytics_id,
    }),
  ],
  providers: [
    // HTTP INTERCEPTORS
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HttpInterceptorTokens.identityServerAuthConf,
      useFactory: () => environment.identityServerAuthConf,
    },
    // AUTH
    {
      provide: AuthTokens.oidcClientId,
      useFactory: () => environment.oidc_clientid,
    },
    {
      provide: AuthTokens.oidcScope,
      useFactory: () => environment.oidc_scope,
    },
    {
      provide: AuthTokens.securityTokenService,
      useFactory: () => environment.LINKS.SECURITY_TOKEN_SERVICE,
    },
    {
      provide: AuthTokens.postLogoutRedirectUri,
      useFactory: () => environment.LINKS.POST_LOGOUT_REDIRECT_URI,
    },
    // SERVICES
    {
      provide: BASE_PATH,
      useFactory: () =>
        environment.palfinger_app_gateway_connected + '/conn-prov-api',
      multi: false,
    },
    {
      provide: BPM_BASE_PATH,
      useFactory: () => environment.palfinger_app_gateway + '/bpm',
    },
    {
      provide: USER_PROFILE_BASE_PATH,
      useFactory: () => environment.LINKS.SECURITY_TOKEN_SERVICE,
    },
    {
      provide: TableSettingsIntl,
      deps: [TranslateService],
      useFactory: getTableSettingsIntl,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
