/**
 * v3 - Business Partner Management
 * Business Partner Management API
 *
 * The version of the OpenAPI document: 3.0
 * Contact: m.kollar@palfinger.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UpsertAccountRequest { 
    
    account_status?: UpsertAccountRequestAccountStatus;
    
    account_type?: UpsertAccountRequestAccountType;
    
    name: string;
    
    name2?: string;
    
    country_code: string;
    
    street_name?: string;
    
    house_number?: string;
    
    street: string;
    
    city: string;
    
    postal_code: string;
    
    email?: string;
    
    url?: string;
    
    phone_number?: string;
    
    fax?: string;
    
    market_ids?: Array<string>;
    
    account_id?: string;
}
export enum UpsertAccountRequestAccountStatus {
    Competitor = 'Competitor',
    Consultant = 'Consultant',
    Customer = 'Customer',
    Investor = 'Investor',
    Partner = 'Partner',
    Influencer = 'Influencer',
    Press = 'Press',
    Prospect = 'Prospect',
    Reseller = 'Reseller',
    Supplier = 'Supplier',
    Vendor = 'Vendor',
    Other = 'Other',
    Debitor = 'Debitor'
};
export enum UpsertAccountRequestAccountType {
    Marketing = 'Marketing',
    InvestorRelation = 'InvestorRelation',
    EndCustomer = 'EndCustomer',
    BodyBuilder = 'BodyBuilder',
    RepresentativeAgent = 'RepresentativeAgent',
    RentalLeasing = 'RentalLeasing',
    Other = 'Other',
    MainDealer = 'MainDealer',
    InternalCommunication = 'InternalCommunication',
    Distributor_GeneralImporter = 'Distributor_GeneralImporter',
    SalesAndServicePartner = 'SalesAndServicePartner',
    SalesAndServiceSubPartner = 'SalesAndServiceSubPartner',
    KeyAccount = 'KeyAccount',
    KeyAccountDealer = 'KeyAccountDealer',
    SparePartsDealer = 'SparePartsDealer',
    OEM = 'OEM'
};



