import { EffectsModule } from '@ngrx/effects';
import { ActionReducerMap } from '@ngrx/store';
import {
  DiagnosticsEffects,
  diagnosticsReducer,
  DiagnosticsState,
} from './diagnostics';
import { inputReducer, InputState } from './input';
import { InputEffects } from './input/input.effects';
import { MessagesEffects } from './messages/messages.effects';
import { messagesReducer, MessagesState } from './messages/messages.reducer';
import { SnackbarEffects } from './snackbar/snackbar.effects';

export interface TelematicDoctorState {
  diagnostics: DiagnosticsState;
  messages: MessagesState;
  input: InputState;
}

export const TelematicDoctorReducers: ActionReducerMap<
  TelematicDoctorState,
  any
> = {
  diagnostics: diagnosticsReducer,
  messages: messagesReducer,
  input: inputReducer,
};

export const TelematicDoctorEffects = [
  EffectsModule.forFeature([
    DiagnosticsEffects,
    MessagesEffects,
    SnackbarEffects,
    InputEffects,
  ]),
];
