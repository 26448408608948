import { TranslateService } from '@ngx-translate/core';
import { TableSettingsIntl } from '@design-system/components/table-settings';

export class TableSettingsI18n {
  constructor(private readonly translate: TranslateService) {}

  getIntl(): TableSettingsIntl {
    const intl = new TableSettingsIntl();

    intl.getColumnName = this.getColumnName.bind(this);

    return intl;
  }

  private getColumnName = (column_name: string) =>
    this.translate.instant('column_names.' + column_name);
}
