/* eslint-disable sonarjs/no-identical-functions */
import { Injectable, inject } from '@angular/core';
import { TableSettingsService } from '@design-system/components/table-settings';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { DoctorService } from '@paldesk/shared-lib/data-access/provisioning-service-generated';
import {
  catchError,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { TelematicDoctorState } from '..';
import { InputSelectors } from '../input';
import { MessagesActions } from './messages.actions';
import { MessagesSelectors } from './messages.selectors';

@Injectable()
export class MessagesEffects {
  private actions$: Actions = inject(Actions);
  private _store: Store<TelematicDoctorState> = inject(Store);

  loadMessagesEquipmentNumber$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        MessagesActions.LoadMessages,
        MessagesActions.SaveQueryMessages,
        MessagesActions.SaveSortMessages,
        MessagesActions.SaveFilterQueryMessages,
      ),
      withLatestFrom(this._store.select(InputSelectors.getIdentifier)),
      filter(([, identifier]) => Boolean(identifier.equipmentNumber?.length)),
      withLatestFrom(
        this._store.select(MessagesSelectors.pageQuery),
        this._store.select(MessagesSelectors.sort),
        this._store.select(MessagesSelectors.filterQuery),
      ),
      switchMap(([[, identifier], query, sort, filterQuery]) =>
        this.doctorService
          .getMessagesForEquipment(
            identifier.equipmentNumber,
            query.pageIndex,
            query.pageSize,
            sort?.active,
            sort?.direction,
            filterQuery?.positionalData
              ? filterQuery?.positionalData === 'true'
              : undefined,
            filterQuery?.objectId,
            filterQuery?.objectValue,
            filterQuery?.signalId,
            filterQuery?.signalValue,
            filterQuery?.fromDate,
            filterQuery?.toDate,
          )
          .pipe(
            map((data) =>
              MessagesActions.LoadMessagesSuccess({ payload: data }),
            ),
            catchError((err) => [
              MessagesActions.LoadMessagesError({ payload: err.error }),
            ]),
          ),
      ),
    ),
  );

  loadMessagesEid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        MessagesActions.LoadMessages,
        MessagesActions.SaveQueryMessages,
        MessagesActions.SaveSortMessages,
        MessagesActions.SaveFilterQueryMessages,
      ),
      withLatestFrom(this._store.select(InputSelectors.getIdentifier)),
      filter(([, identifier]) => Boolean(identifier.eid?.length)),
      withLatestFrom(
        this._store.select(MessagesSelectors.pageQuery),
        this._store.select(MessagesSelectors.sort),
        this._store.select(MessagesSelectors.filterQuery),
      ),
      switchMap(([[, identifier], query, sort, filterQuery]) =>
        this.doctorService
          .getMessagesForDevice(
            identifier.eid,
            query?.pageIndex,
            query?.pageSize,
            sort?.active,
            sort?.direction,
            filterQuery?.positionalData
              ? filterQuery?.positionalData === 'true'
              : undefined,
            filterQuery?.objectId,
            filterQuery?.objectValue,
            filterQuery?.signalId,
            filterQuery?.signalValue,
            filterQuery?.fromDate,
            filterQuery?.toDate,
          )
          .pipe(
            map((data) =>
              MessagesActions.LoadMessagesSuccess({ payload: data }),
            ),
            catchError((err) => [
              MessagesActions.LoadMessagesError({ payload: err.error }),
            ]),
          ),
      ),
    ),
  );

  LoadUserTableSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MessagesActions.LoadUserTableSettingsMessages),
      switchMap((payload) =>
        this.tableSettingsService
          .get(payload.settingsKey, payload.allColumns)
          .pipe(
            map((data) =>
              MessagesActions.LoadUserTableSettingsMessagesSuccess({
                payload: data,
              }),
            ),
          ),
      ),
    ),
  );

  constructor(
    private doctorService: DoctorService,
    private tableSettingsService: TableSettingsService,
  ) {}
}
