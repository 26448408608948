import { createFeatureSelector, createSelector } from '@ngrx/store';
import { spotlightAdapter, SpotlightState } from './spotlight.reducer';

export const spotlightState =
  createFeatureSelector<SpotlightState>('spotlightModule');

export namespace SpotlightSelectors {
  export const {
    selectIds: spotlightIds,
    selectEntities: spotlightEntities,
    selectAll: allSpotlights,
    selectTotal: spotlightsTotal,
  } = spotlightAdapter.getSelectors(spotlightState);

  export const isSpotLightRegistered = (id: string) =>
    createSelector(spotlightState, (state) => !!state.entities[id]);

  export const isSpotLightOpen = (id: string) =>
    createSelector(spotlightState, (state) => state.idOfOpenSpotlight === id);

  export const indexById = (id: string) =>
    createSelector(allSpotlights, (spotlights) =>
      spotlights.findIndex((x) => x.id === id),
    );
}
