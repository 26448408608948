/**
 * v3 - Business Partner Management
 * Business Partner Management API
 *
 * The version of the OpenAPI document: 3.0
 * Contact: m.kollar@palfinger.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CrmSegmentItemResponse } from './crmSegmentItemResponse';


export interface CrmAccountResponse { 
    
    account_id: string;
    
    account_number?: string;
    
    account_status?: CrmAccountResponseAccountStatus;
    
    account_type?: CrmAccountResponseAccountType;
    
    business_partner_number?: string;
    
    customer_number?: string;
    
    country_code: string;
    
    company_name: string;
    
    company_name2?: string;
    
    postal_code?: string;
    
    city?: string;
    
    street_name?: string;
    
    house_number?: string;
    
    street?: string;
    
    phone_number?: string;
    
    fax?: string;
    
    email?: string;
    
    url?: string;
    
    language?: string;
    
    created_date?: Date;
    
    is_registered_in_bpm: boolean;
    
    is_invited_in_bpm: boolean;
    
    bpm_id?: number;
    
    partner_type?: number;
    
    tax_number?: string;
    
    is_deleted: boolean;
    
    is_active: boolean;
    
    segments: Array<CrmSegmentItemResponse>;
}
export enum CrmAccountResponseAccountStatus {
    Competitor = 'Competitor',
    Consultant = 'Consultant',
    Customer = 'Customer',
    Investor = 'Investor',
    Partner = 'Partner',
    Influencer = 'Influencer',
    Press = 'Press',
    Prospect = 'Prospect',
    Reseller = 'Reseller',
    Supplier = 'Supplier',
    Vendor = 'Vendor',
    Other = 'Other',
    Debitor = 'Debitor'
};
export enum CrmAccountResponseAccountType {
    Marketing = 'Marketing',
    InvestorRelation = 'InvestorRelation',
    EndCustomer = 'EndCustomer',
    BodyBuilder = 'BodyBuilder',
    RepresentativeAgent = 'RepresentativeAgent',
    RentalLeasing = 'RentalLeasing',
    Other = 'Other',
    MainDealer = 'MainDealer',
    InternalCommunication = 'InternalCommunication',
    Distributor_GeneralImporter = 'Distributor_GeneralImporter',
    SalesAndServicePartner = 'SalesAndServicePartner',
    SalesAndServiceSubPartner = 'SalesAndServiceSubPartner',
    KeyAccount = 'KeyAccount',
    KeyAccountDealer = 'KeyAccountDealer',
    SparePartsDealer = 'SparePartsDealer',
    OEM = 'OEM'
};



