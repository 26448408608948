import {
  CrmAccountResponse,
  CrmAccountResponseAccountStatus,
  CrmAccountResponseAccountType,
} from '@data-access/bpm-generated';
import { testCrmSegmentItemResponse } from './crmSegmentItemResponse';

export const testCrmAccountResponse: CrmAccountResponse = {
  account_id: '0',
  account_number: '123',
  account_status: CrmAccountResponseAccountStatus.Competitor,
  account_type: CrmAccountResponseAccountType.EndCustomer,
  business_partner_number: '3156',
  customer_number: '165849',
  country_code: 'DE',
  company_name: 'Test Company Name',
  company_name2: 'Test Company Name 2',
  postal_code: '5020',
  city: 'Salzburg',
  street_name: 'Test street name',
  house_number: '5',
  street: 'Test street',
  phone_number: '0660123456',
  fax: '32654',
  email: 'mustermail@email.at',
  url: 'test Url',
  language: 'DE',
  created_date: new Date(),
  is_registered_in_bpm: false,
  is_invited_in_bpm: false,
  is_deleted: false,
  is_active: true,
  bpm_id: 12,
  partner_type: 2,
  tax_number: '123456',
  segments: [testCrmSegmentItemResponse],
};
